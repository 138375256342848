import {debounce} from '../services/helpers';

const MINIMAL_PADDING_OF_CHILD = 8;
const ARROWS_COUNT = 2;
const TIMEOUT = 16;
const RIGHT_SCROLL_TRESHOLD = 1;

const initCustomSlider = ({slidesPerMove, $container, $slides, $leftArrow, $rightArrow}) => {
  if (!$container || !$slides?.length || !$leftArrow || !$rightArrow) {
    return;
  }

  const containerWidth = $container.clientWidth;
  const slidesWidth = $slides.map($slide => $slide.clientWidth);
  const maxSlideWidth = slidesWidth.reduce((accSlideWidth, currentWidth) => Math.max(accSlideWidth, currentWidth), 0);
  const fullScrollWidth = slidesWidth.reduce((prev, next) => prev + next, 0);

  let currentScroll = $container.scrollLeft;

  let isScrollChangingWhenScrolling = true;

  if (fullScrollWidth > containerWidth) {
    $rightArrow.classList.remove('hidden');
  }

  let scrollStepWidth = 0;

  if (slidesPerMove) {
    scrollStepWidth = maxSlideWidth * slidesPerMove;
  } else {
    scrollStepWidth = containerWidth - ARROWS_COUNT * $rightArrow.clientWidth;
  }

  const applyArrowsVisibility = currentScrollWidth => {
    if (currentScrollWidth > MINIMAL_PADDING_OF_CHILD) {
      $leftArrow.classList.remove('hidden');
    } else {
      $leftArrow.classList.add('hidden');
    }

    if (currentScrollWidth + containerWidth >= fullScrollWidth - MINIMAL_PADDING_OF_CHILD) {
      $rightArrow.classList.add('hidden');
    } else {
      $rightArrow.classList.remove('hidden');
    }
  };

  const debouncedScrollListener = debounce(() => {
    if (isScrollChangingWhenScrolling) {
      currentScroll = Math.round($container.scrollLeft);
      applyArrowsVisibility(currentScroll);
    }
  }, TIMEOUT);

  $container.addEventListener('scroll', debouncedScrollListener);

  $rightArrow.addEventListener('click', () => {
    isScrollChangingWhenScrolling = false;
    $container.removeEventListener('scroll', debouncedScrollListener);
    const scrollToRightWidth =
      currentScroll + scrollStepWidth + containerWidth >= fullScrollWidth - RIGHT_SCROLL_TRESHOLD
        ? fullScrollWidth - scrollStepWidth
        : currentScroll + scrollStepWidth;

    currentScroll = scrollToRightWidth;
    $container.scrollLeft = scrollToRightWidth;
    applyArrowsVisibility(currentScroll);
    setTimeout(() => {
      isScrollChangingWhenScrolling = true;
      $container.addEventListener('scroll', debouncedScrollListener);
    }, TIMEOUT);
  });

  $leftArrow.addEventListener('click', () => {
    isScrollChangingWhenScrolling = false;
    $container.removeEventListener('scroll', debouncedScrollListener);
    const scrollToLeftWidth = currentScroll - scrollStepWidth < 0
      ? 0
      : currentScroll - scrollStepWidth;

    currentScroll = scrollToLeftWidth;
    $container.scrollLeft = scrollToLeftWidth;
    applyArrowsVisibility(currentScroll);
    setTimeout(() => {
      isScrollChangingWhenScrolling = true;
      $container.addEventListener('scroll', debouncedScrollListener);
    }, TIMEOUT);
  });
};

export {initCustomSlider};
