export const enableScroll = () => {
  const scrollY = document.body.style.top;

  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo({
    top: parseInt(scrollY || '0', 10) * -1,
    left: 0,
    behavior: 'instant'
  });
};

export const disableScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
};
