import {getTrackToJitsu} from '../track-to-jitsu';

const navigationTapLogEvent = getTrackToJitsu('tap');
const navigationChangeLogEvent = getTrackToJitsu('change');

const defaulteventParams = {
  event_target_layout_section: 'left_drawer',
  feature: 'navigation'
};

const BURGER_MENU_CATEGORIES_COMPONENTS = [
  'language_toggler',
  'featured_menu',
  'popular_menu',
  'category_menu',
  'social_networks_menu',
  'downloads_menu',
  'about_us_menu',
  'policy_menu',
  'editorial_team_contacts',
  'main_office_contacts',
  'feedback_form_link',
  'corporate_clients_link'
];

const HEADER_COMPONENT_NAME = 'header';
const APP_BAR_SECTION = 'app_bar';
const FEATURED_MENU_COMPONENT = 'featured_menu';

const NavigationEventsLogger = {
  clickMenuButton: () => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'menu_toggler',
      event_target_text_content: 'Меню',
      event_target_component: HEADER_COMPONENT_NAME,
      event_target_layout_section: APP_BAR_SECTION
    });
  },
  clickHeaderLogo: () => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'logo',
      event_target_component: HEADER_COMPONENT_NAME,
      event_target_layout_section: APP_BAR_SECTION
    });
  },
  clickFeatureMenuItem: (name, id) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'menu_item',
      event_target_text_content: name,
      event_target_component: FEATURED_MENU_COMPONENT,
      event_target_index: id,
      event_target_layout_section: APP_BAR_SECTION
    });
  },
  changeLangInHeader: newLang => {
    navigationChangeLogEvent({
      ...defaulteventParams,
      event_target: 'language_toggler',
      event_target_value: newLang,
      event_target_component: FEATURED_MENU_COMPONENT,
      event_target_layout_section: APP_BAR_SECTION
    });
  },
  changeLangInBurger: (newLang, sectionId) => {
    navigationChangeLogEvent({
      ...defaulteventParams,
      event_target: 'language_toggler',
      event_target_value: newLang,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionId],
      event_target_component_index: sectionId
    });
  },
  closeBurgerWithButton: () => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'close_button',
      event_target_text_content: 'Закрыть'
    });
  },
  closeBurgerWithHeaderButton: () => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'close_button',
      event_target_component: HEADER_COMPONENT_NAME,
      event_target_layout_section: APP_BAR_SECTION
    });
  },
  closeBurgerByOverlayClick: () => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'overlay'
    });
  },
  clickOnCategory: (name, index, sectionIndex) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'menu_item',
      event_target_text_content: name,
      event_target_index: index,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  },
  clickOnSubCategory: (name, index, sectionIndex) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'menu_subitem',
      event_target_text_content: name,
      event_target_index: index,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  },
  changeCategoryBehavior: (isExpanded, index, sectionIndex) => {
    navigationChangeLogEvent({
      ...defaulteventParams,
      event_target: 'menu_item_toggler',
      event_target_index: index,
      event_target_value: isExpanded ? 'expand' : 'collapse',
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  },
  clickFeedbackFormLink: (sectionIndex, text) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'feedback_form_link',
      event_target_text_content: text,
      event_target_component: 'feedback_form_link',
      event_target_component_index: sectionIndex
    });
  },
  clickCorporateClientsLink: (sectionIndex, text) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'corporate_clients_link',
      event_target_text_content: text,
      event_target_component: 'corporate_clients_link',
      event_target_component_index: sectionIndex
    });
  },
  clickSocialMenuItem: (text, index, sectionIndex) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'social_networks_menu',
      event_target_index: index,
      event_target_alt_text: text,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  },
  clickDownloadLinks: (text, index, sectionIndex) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'downloads_menu',
      event_target_index: index,
      event_target_alt_text: text,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  },
  clickEditorialTeamContacts: (name, index, sectionIndex) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'menu_item',
      event_target_text_content: name,
      event_target_index: index,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  },
  clickMainOfficeContacts: (name, sectionIndex) => {
    navigationTapLogEvent({
      ...defaulteventParams,
      event_target: 'main_office_contacts',
      event_target_text_content: name,
      event_target_component: BURGER_MENU_CATEGORIES_COMPONENTS[sectionIndex],
      event_target_component_index: sectionIndex
    });
  }
};

export {NavigationEventsLogger};
