import {initCustomSlider} from './modules/custom-slider';
import {NavigationEventsLogger} from './services/analytics/navigation';
import {debounce} from './services/helpers';
import {disableScroll, enableScroll} from './services/scroll-helpers';
import {BURGER_MENU_SEARCH_PARAM_VALUE, MODAL_SEARCH_PARAM_KEY} from './website-ui/constants/url';

const headerSettings = {
  isBurgerOpen: false,
  isStickyHeaderEnabled: true
};
const TOP_BANNER_HEIGHT = 96;
const STICKY_HEADER_TIMEOUT = 16;
const isDesktop = window.Site.targeting.platform === 'desktop';

const $headerReducer = document.querySelector('.js-header-reducer');

function initStickyHeaderLogo(
  settings = {isBurgerOpen: false, isStickyHeaderEnabled: true},
  timeout = STICKY_HEADER_TIMEOUT
) {
  const $logo = document.querySelector('.js-header-logo-sticky');

  if (!$logo) {
    return;
  }

  const $pageTopPlaceholder = document.querySelector('.js-page-top');

  window.addEventListener('scroll', debounce(() => {
    if (!settings.isStickyHeaderEnabled) {
      return;
    }

    if (window.scrollY > $pageTopPlaceholder.offsetHeight) {
      $logo.classList.add('sticky');
    } else {
      $logo.classList.remove('sticky');
    }
  }, timeout));
}

function initHeaderMenu() {
  if (!isDesktop) {
    const $container = document.querySelector('.js-header-nav-slider-container');
    const $items = [...(document.querySelectorAll('.js-header-nav-slider-item') || [])];
    const $leftArrow = document.querySelector('.js-header-nav-left-arrow');
    const $rightArrow = document.querySelector('.js-header-nav-right-arrow');

    initCustomSlider({$container, $slides: $items, $leftArrow, $rightArrow});
  } else {
    const $headerMenu = document.querySelector('.js-header-nav-slider-container');
    const $headerMoreButton = document.querySelector('.js-header-more-button');

    if (!$headerMenu) {
      return;
    }

    if ($headerMenu.offsetWidth >= $headerMenu.scrollWidth) {
      $headerMenu.classList.add('visible');
      return;
    }
    $headerMoreButton.classList.add('visible');

    const $headerSubMenu = document.querySelector('.js-header-more-list');
    const $links = [...$headerMenu.querySelectorAll('.header__main-menu-links-item')];

    while ($headerMenu.offsetWidth < $headerMenu.scrollWidth) {
      const $lastElement = $links.pop();

      $headerSubMenu.appendChild($lastElement);
    }

    $headerMenu.classList.add('visible');
    $headerMoreButton.onclick = () => {
      $headerMoreButton.classList.toggle('open');
    };
    window.addEventListener('click', e => {
      if (!$headerMoreButton.contains(e.target)) {
        $headerMoreButton.classList.remove('open');
      }
    });
  }
}

function initBurgerMenu(settings = {isBurgerOpen: false, isStickyHeaderEnabled: true}) {
  const isMobile = window.Site.targeting.platform === 'smartphone';
  const $headerBurgerButton = document.querySelector('.js-burger-button');
  const $burgerCloseIcon = document.querySelector('.js-burger-close-button');
  const $burgerOverlay = document.querySelector('.js-burger-overlay');
  const $burgerMenu = document.querySelector('.js-burger-menu');
  const $burgerButtons = [...document.querySelectorAll('.js-burger-collapse')];
  const $burgerContainer = document.querySelector('.js-burger-container');
  const searchParams = new URLSearchParams(window.location.search);

  if (!$headerBurgerButton || !$burgerCloseIcon || !$burgerMenu || !$burgerOverlay || !$burgerContainer) {
    return;
  }

  function toggleBurgerVisible() {
    settings.isBurgerOpen = !settings.isBurgerOpen;
    settings.isStickyHeaderEnabled = !settings.isBurgerOpen;

    if (isMobile && settings.isBurgerOpen && window.scrollY < TOP_BANNER_HEIGHT) {
      window.scrollTo(0, TOP_BANNER_HEIGHT);
      $headerReducer.classList.add('mobile__open');
    }

    if (settings.isBurgerOpen) {
      $burgerMenu.classList.add('open');
      $headerBurgerButton.classList.add('open');
      disableScroll();
      $burgerContainer.classList.add('open');
    } else {
      enableScroll();
      $burgerContainer.classList.remove('open');
      $headerReducer.classList.remove('mobile__open');
    }
  }

  if (searchParams.get(MODAL_SEARCH_PARAM_KEY) === BURGER_MENU_SEARCH_PARAM_VALUE) {
    toggleBurgerVisible();
  }

  function popStateHandler() {
    const newParams = new URLSearchParams(window.location.search);

    if ((!settings.isBurgerOpen && newParams.get(MODAL_SEARCH_PARAM_KEY) === BURGER_MENU_SEARCH_PARAM_VALUE) ||
    (settings.isBurgerOpen && newParams.get(MODAL_SEARCH_PARAM_KEY) !== BURGER_MENU_SEARCH_PARAM_VALUE)) {
      toggleBurgerVisible();
    }
  }

  window.addEventListener('popstate', popStateHandler);

  $burgerContainer.addEventListener('animationend', () => {
    if (!settings.isBurgerOpen) {
      $headerBurgerButton.classList.remove('open');
      $burgerMenu.classList.remove('open');
    }
  });

  function navigateForBurger(isOpen = false, state = null, callback) {
    const url = new URL(window.location.href);

    if (isOpen) {
      url.searchParams.delete(MODAL_SEARCH_PARAM_KEY);
    } else {
      url.searchParams.set(MODAL_SEARCH_PARAM_KEY, BURGER_MENU_SEARCH_PARAM_VALUE);
    }

    if (!window.history.state?.from) {
      window.history.pushState(state, null, url);
      const navEvent = new PopStateEvent('popstate');

      window.dispatchEvent(navEvent);
    } else {
      window.history.back();
    }
    callback();
  }

  function onHeaderButtonClick() {
    if (settings.isBurgerOpen) {
      navigateForBurger(
        settings.isBurgerOpen,
        null,
        () => NavigationEventsLogger.closeBurgerWithHeaderButton()
      );
    } else {
      navigateForBurger(
        settings.isBurgerOpen,
        {from: 'header'},
        () => NavigationEventsLogger.clickMenuButton()
      );
    }
  }

  function onOverlayClick() {
    navigateForBurger(
      true,
      null,
      () => NavigationEventsLogger.closeBurgerByOverlayClick()
    );
  }

  function onBurgerIconClick() {
    navigateForBurger(
      true,
      null,
      () => NavigationEventsLogger.closeBurgerWithButton()
    );
  }

  $headerBurgerButton.onclick = onHeaderButtonClick;
  $burgerOverlay.onclick = onOverlayClick;
  $burgerCloseIcon.onclick = onBurgerIconClick;

  if (!$burgerButtons.length) {
    return;
  }

  const $elementsToCollapse = $burgerButtons.map($button => ({
    button: $button,
    parent: $button.parentElement
  }));

  $elementsToCollapse.forEach($element => {
    $element.button.onclick = () => {
      $element.parent.classList.toggle('open');
      const isExpanded = $element.parent.classList.contains('open');
      const index = $element.parent?.dataset.itemId;
      const sectionId = $element.button.closest('.js-analytics-section')?.dataset.sectionId || null;

      NavigationEventsLogger.changeCategoryBehavior(isExpanded, index, sectionId);
    };
  });
}

if (isDesktop) {
  initStickyHeaderLogo(headerSettings, STICKY_HEADER_TIMEOUT);
}

initHeaderMenu();
initBurgerMenu(headerSettings);

function initHeaderReducerAnalytics() {
  if (!$headerReducer) {
    return;
  }

  $headerReducer.addEventListener('click', e => {
    const $logo = e.target.closest('.js-header-logo');

    if ($logo) {
      NavigationEventsLogger.clickHeaderLogo();
      return;
    }
  });
}

function initHeaderNavAnalytics() {
  const $featuredMenu = document.querySelector('.js-header-featured-menu');

  if (!$featuredMenu) {
    return;
  }

  $featuredMenu.addEventListener('click', e => {
    const $featuredLink = e.target.closest('.js-featured-menu-link');
    const $headerLang = e.target.closest('.js-header-lang');

    if ($featuredLink) {
      const text = $featuredLink.innerText;
      const id = $featuredLink.dataset.id;

      NavigationEventsLogger.clickFeatureMenuItem(text, id);
      return;
    }

    if ($headerLang) {
      const newLang = $headerLang.dataset.lang;

      NavigationEventsLogger.changeLangInHeader(newLang);
      return;
    }
  });
}

function initBurgerMenuAnalytics() {
  const $burger = document.querySelector('.js-burger-menu');

  function getSectionIndex(e) {
    const $section = e.target.closest('.js-analytics-section');

    if (!$section) {
      return null;
    }

    return $section.dataset?.sectionId;
  }

  if (!$burger) {
    return;
  }

  $burger.addEventListener('click', e => {
    const $link = e.target.closest('a');

    if (!$link) {
      return;
    }

    const sectionId = getSectionIndex(e);

    if (e.target.closest('.js-burger-lang')) {
      const newLang = e.target.closest('.js-burger-lang').dataset?.lang;

      NavigationEventsLogger.changeLangInBurger(newLang, sectionId);
      return;
    }

    if (e.target.closest('.js-feedback-section')) {
      NavigationEventsLogger.clickFeedbackFormLink(getSectionIndex(e), e.target.innerText);
      return;
    }

    if (e.target.closest('.js-corporate-section')) {
      NavigationEventsLogger.clickCorporateClientsLink(getSectionIndex(e), e.target.innerText);
      return;
    }

    if (e.target.closest('.js-editor-office-section')) {
      const index = e.target.closest('li')?.dataset.id;
      const text = e.target.innerText;

      NavigationEventsLogger.clickEditorialTeamContacts(text, index, sectionId);
      return;
    }

    if (e.target.closest('.js-main-office-section')) {
      const text = e.target.innerText;

      NavigationEventsLogger.clickMainOfficeContacts(text, sectionId);
      return;
    }

    if (e.target.closest('.js-mobile-downloads')) {
      const $image = e.target.closest('img');
      const text = $image?.getAttribute('alt');
      const index = e.target.closest('li')?.dataset.id;

      NavigationEventsLogger.clickDownloadLinks(text, index, sectionId);
      return;
    }

    if (e.target.closest('.js-social-list')) {
      const $image = e.target.closest('img');
      const text = $image?.getAttribute('alt');
      const index = e.target.closest('li')?.dataset.id;

      NavigationEventsLogger.clickSocialMenuItem(text, index, sectionId);
      return;
    }

    if (e.target.closest('.js-category-section')) {
      const $subItem = e.target.closest('.js-category-subitem');

      if ($subItem) {
        const text = e.target.innerText;
        const index = $subItem.dataset.subitemId;

        NavigationEventsLogger.clickOnSubCategory(text, index, sectionId);
        return;
      }

      const $menuCategory = e.target.closest('.js-burger-item');

      if ($menuCategory) {
        const index = $menuCategory.dataset.itemId;
        const text = e.target.innerText;

        NavigationEventsLogger.clickOnCategory(text, index, sectionId);
        return;
      }
      const $footerCategory = e.target.closest('li');

      if ($footerCategory) {
        const index = $footerCategory.dataset.id;
        const text = e.target.innerText;

        NavigationEventsLogger.clickOnCategory(text, index, sectionId);
      }
    }
  });
}

initHeaderReducerAnalytics();
initHeaderNavAnalytics();
initBurgerMenuAnalytics();
